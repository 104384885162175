import moment from 'moment-timezone'
import { firebase, backgroundColl } from '@/utils/firebase'

import { IBoardBackGround } from '@/utils/types'

export class BackGroundBoard {
    private userId: string | undefined = ''

    get ref() {
        if (!this.userId) {
            throw new Error('Unknow userId')
        }

        return backgroundColl.doc(this.userId).collection('customsize')
    }

    parse(doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) {
        return { id: doc.id, ...doc.data() } as IBoardBackGround
    }

    background(userId?: string) {
        this.userId = userId
        return this
    }

    list = async (): Promise<IBoardBackGround[]> => {
        const backgrounds: IBoardBackGround[] = []

        const docs = (await this.ref.limit(100).get()).docs

        docs.map(doc => {
            backgrounds.push(this.parse(doc))
        })

        return backgrounds
    }

    get = async (id: string) => {
        const doc = await this.ref.doc(id).get()
        return { id: doc.id, ...doc.data() }
    }

    add = async (background: IBoardBackGround) => {
        background.createdAt = moment().unix()
        background.updatedAt = moment().unix()
        this.ref.add({ ...background })
    }

    update = async (background: IBoardBackGround) => {
        background.updatedAt = moment().unix()
        // eslint-disable-next-line
        const { id, ...updateData } = background

        await this.ref.doc(id).update({
            ...updateData,
        })
    }

    delete = async (id: string) => {
        await this.ref.doc(id).delete()
    }

    deleteAll = async () => {
        return backgroundColl.doc(this.userId).delete()
    }
}

export default new BackGroundBoard()
