<template>
  <a-layout-footer class="text-center bg-gray-200 py-3">
    Copyright ©2021 Sango CorkBoard. All rights reserved.
  </a-layout-footer>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
  components: {},
})
export default class AppFooter extends Vue {}
</script>
