import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import NotFound from '@/components/common/NotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      restricted: true,
      menuKey: 'home',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/boards/:id',
    name: 'board_view',
    component: () => import(/* webpackChunkName: "board_view" */ '../views/BoardView.vue'),
    meta: {
      restricted: false,
      menuKey: 'board',
    },
  },
  {
    path: '/boards/:id/edit',
    name: 'board_edit',
    component: () => import(/* webpackChunkName: "board_edit" */ '../views/BoardEdit.vue'),
    meta: {
      restricted: true,
      menuKey: 'board',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/boards/gives',
    name: 'gives',
    component: () => import(/* webpackChunkName: "gives" */ '../views/Home.vue'),
    meta: {
      restricted: true,
      menuKey: 'gives',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/boards/received',
    name: 'received',
    component: () => import(/* webpackChunkName: "received" */ '../views/Home.vue'),
    meta: {
      restricted: true,
      menuKey: 'received',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/boards/waiting-for-you',
    name: 'waiting_for_you',
    component: () => import(/* webpackChunkName: "waiting_for_you" */ '../views/Home.vue'),
    meta: {
      restricted: true,
      menuKey: 'waiting_for_you',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/boards',
    name: 'boards',
    component: () => import(/* webpackChunkName: "home" */ '../views/Board.vue'),
    meta: {
      restricted: false,
      menuKey: 'boards',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/users/:id',
    name: 'user_profile_view',
    component: () => import(/* webpackChunkName: "user_profile_view" */ '../views/UserProfile.vue'),
    meta: {
      restricted: true,
      menuKey: 'users',
    },
  },
  {
    path: '/profile/edit',
    name: 'user_profile_edit',
    component: () => import(/* webpackChunkName: "user_profile_edit" */ '../views/ProfileEdit.vue'),
    meta: {
      restricted: true,
      menuKey: 'users',
    },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue'),
    meta: {
      restricted: true,
      menuKey: 'help',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFound,
    meta: {
      header: {
        logo: false,
        homeButtton: true,
      },
      restricted: false,
    },
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
