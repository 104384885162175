<template>
  <SvgBase iconName="VerticalThreeDots">
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      style="enable-background: new 0 0 32 32"
      xml:space="preserve"
    >
      <line class="st0" :style="{ stroke: color }" x1="3" y1="11" x2="29" y2="11" />
      <g>
        <path
          :style="{ stroke: color }"
          d="M7,9C6.7,9,6.5,8.9,6.3,8.7C6.1,8.5,6,8.3,6,8c0-0.3,0.1-0.5,0.3-0.7c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1
    C6.7,7,6.7,7,6.8,7c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.3
    C8,7.7,8,7.9,8,8c0,0.1,0,0.3-0.1,0.4C7.9,8.5,7.8,8.6,7.7,8.7C7.5,8.9,7.3,9,7,9z"
        />
      </g>
      <g>
        <path
          :style="{ stroke: color }"
          d="M10,9C9.7,9,9.5,8.9,9.3,8.7C9.1,8.5,9,8.3,9,8c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2
    C10,6.9,10.4,7,10.7,7.3c0.1,0.1,0.2,0.2,0.2,0.3C11,7.7,11,7.9,11,8c0,0.3-0.1,0.5-0.3,0.7C10.5,8.9,10.3,9,10,9z"
        />
      </g>
      <g>
        <path
          :style="{ stroke: color }"
          d="M13,9c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.2-0.3C12,8.3,12,8.1,12,8c0-0.1,0-0.3,0.1-0.4
    c0.1-0.1,0.1-0.2,0.2-0.3c0.4-0.4,1-0.4,1.4,0c0.1,0.1,0.2,0.2,0.2,0.3C14,7.7,14,7.9,14,8c0,0.1,0,0.3-0.1,0.4
    c-0.1,0.1-0.1,0.2-0.2,0.3C13.5,8.9,13.3,9,13,9z"
        />
      </g>
      <line class="st0" :style="{ stroke: color }" x1="11" y1="15" x2="21" y2="15" />
      <line class="st0" :style="{ stroke: color }" x1="11" y1="31" x2="21" y2="31" />
      <path class="st0" :style="{ stroke: color }" d="M12,15L12,15c0,3.1,1.5,6.1,4,8l0,0l0,0c2.5-1.9,4-4.9,4-8v0" />
      <path class="st0" :style="{ stroke: color }" d="M20,31L20,31c0-3.1-1.5-6.1-4-8l0,0l0,0c-2.5,1.9-4,4.9-4,8v0" />
      <path
        class="st0"
        :style="{ stroke: color }"
        d="M19.2,27H27c1.1,0,2-0.9,2-2V7c0-1.1-0.9-2-2-2H5C3.9,5,3,5.9,3,7v18c0,1.1,0.9,2,2,2h7.8"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconUser extends Vue {}
</script>
<style type="text/css" scoped>
.st0 {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st1 {
  fill: none;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
</style>
