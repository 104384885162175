<template>
  <a-tag
    class="mb-1"
    color="blue"
    :spinning="loading"
    v-if="!loading"
    :loading="true"
    closable
    @close="onRemove"
    >{{ user?.displayName || email }}</a-tag
  >
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { SearchOutlined } from '@ant-design/icons-vue'
import { IUser } from '@/utils/types'
import { findUserByEmail } from '@/services/users'

@Options({
  components: { SearchOutlined },
  directives: { maska },
  emits: ['update:onRemove'],
})
export default class SearchUserInput extends Vue {
  @Prop({ default: '' })
  email!: string

  user!: IUser
  loading = true

  @Emit('update:onRemove')
  onRemove() {
    return this.email
  }

  async created() {
    this.user = (await findUserByEmail(this.email))?.data()
    this.loading = false
  }
}
</script>
