<template>
  <div class="app-content" v-if="alreadyGetProfileOnInit || isLoginPage">
    <template v-if="!isLoginPage">
      <AppSidebar />
    </template>

    <!-- Not found -->
    <NotFound v-if="isNotFound" />

    <!-- Login layout -->
    <div v-if="isLoginPage" class="py-10">
      <router-view />
    </div>

    <AppFooter v-if="!isLoginPage" />

    <AppDialog />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { firebase } from '@/utils/firebase'
import { RESET_PAGE_NOT_FOUND, SET_PAGE_NOT_FOUND, LOGIN_FROM_FIREBASE, SAVE_PROFILE } from '@/store/actions'

import errorHandler from '@/utils/errorHandler'
import { IUser } from '@/utils/types'

import AppSidebar from '@/components/common/AppSidebar.vue'
import AppDialog from '@/components/common/AppDialog.vue'
import NotFound from '@/components/common/NotFound.vue'
import AppFooter from '@/components/common/AppFooter.vue'
import { findUserByEmail, firebaseUser } from './services/users'

@Options({
  components: {
    AppSidebar,
    AppDialog,
    AppFooter,
    NotFound,
  },
})
export default class App extends Vue {
  get alreadyGetProfileOnInit() {
    return this.$store.getters.alreadyGetProfileOnInit
  }

  get isNotFound() {
    return this.$store.state.isNotFound
  }

  get isLoginPage() {
    return this.$route.name === 'login' || this.$route.name === 'register'
  }

  get shouldDisplayRestrictedContent(): boolean {
    return this.isLoginPage || this.isAuth
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get isRestrictedPage() {
    return this.$route.meta.restricted
  }

  get user() {
    return this.$store.getters.userInfo
  }

  redirectLogin() {
    this.$router.replace({
      name: 'login',
      params: {},
    })
  }

  @Watch('$route.params')
  @Watch('alreadyGetProfileOnInit')
  async onRouterChange() {
    if (!this.isLoginPage) {
      if (this.alreadyGetProfileOnInit && !this.isAuth && this.isRestrictedPage) {
        this.redirectLogin()
      }
    } else if (this.isAuth) {
      this.$router.replace({
        name: 'home',
      })
    }

    if (this.$route.name === 'notFound') {
      this.$store.dispatch(SET_PAGE_NOT_FOUND)
    } else {
      this.$store.dispatch(RESET_PAGE_NOT_FOUND)
    }
  }

  async doLoginSocial(gUser: IUser) {
    const { dispatch } = this.$store

    await dispatch(LOGIN_FROM_FIREBASE, {
      ...gUser,
    }).catch((error: unknown) => {
      console.log(error)
      errorHandler(['不明なエラーが発生されました。'])
    })
  }

  listenFirebaseAuthChange() {
    if (this.isAuth) {
      return
    }

    firebase.auth().onAuthStateChanged(
      async () => {
        const fuser = firebaseUser()
        if (fuser?.email) {
          const duser = await findUserByEmail(fuser?.email)
          await this.doLoginSocial(duser.data())
        } else {
          this.$store.dispatch(SAVE_PROFILE, null)
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  mounted() {
    this.doLoginSocial(this.user)
    this.listenFirebaseAuthChange()
  }
}
</script>
<style lang="less">
@import '~@/assets/css/mixins.less';
</style>
