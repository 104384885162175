<template>
  <div class="dropdown--left-sider-mobile">
    <div class="dropdown--left-sider-mobile__overlay" v-if="menuOpen" @click="menuOpen = !menuOpen"></div>
    <MenuOutlined @click="menuOpen = !menuOpen" class="pt-4 text-2xl text-white" />
    <a-menu
      theme="dark"
      :selectedKeys="[activeRouteKey]"
      :key="activeRouteKey"
      class="flex-grow rounded-none"
      :class="{ open: menuOpen }"
    >
      <div class="flex-none px-2 ant-layout-sider__account-box">
        <div class="flex items-center gap-2">
          <UserAvatar />
          <div class="flex-grow truncate">
            <a @click.prevent @click="gotoUserProfile" class="font-bold text-white">
              {{ userInfo.displayName }}
            </a>
          </div>

          <CloseCircleOutlined @click="menuOpen = !menuOpen" class="text-2xl text-white" />
        </div>
      </div>

      <span class="block py-2 pl-4 leading-none text-gray-400">Menu</span>
      <a-menu-item key="gives_" @click="goto('gives')">
        <ExportOutlined style="font-size: 1.1rem" />
        <span>You gave</span>
      </a-menu-item>
      <a-menu-item key="received_" @click="goto('received')">
        <ImportOutlined style="font-size: 1.1rem" />
        <span>You received</span>
      </a-menu-item>
      <a-menu-item key="waiting_for_you_" @click="goto('waiting_for_you')">
        <HourglassOutlined style="font-size: 1.1rem" />
        <span>Waiting for you</span>
      </a-menu-item>

      <a-menu-item key="logout" @click="logout">
        <LogoutOutlined style="font-size: 1.1rem" />
        <span>Logout</span>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'

import {
  ExportOutlined,
  ImportOutlined,
  HourglassOutlined,
  LogoutOutlined,
  MenuOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons-vue'
import { Watch } from 'vue-property-decorator'
import AppLogo from '@/components/common/AppLogo.vue'
import UserAvatar from '@/components/common/UserAvatar.vue'
import { IUser } from '@/utils/types'
import { LOGOUT } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'

@Options({
  components: {
    AppLogo,
    UserAvatar,
    MenuOutlined,
    CloseCircleOutlined,
    LogoutOutlined,
    ExportOutlined,
    ImportOutlined,
    HourglassOutlined,
  },
})
export default class AppSidebarMobile extends Vue {
  menuOpen = false

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get activeRouteKey() {
    return `${this.$route.meta?.menuKey}_`
  }

  @Watch('$route')
  async onRouterChange() {
    this.menuOpen = false
  }

  logout() {
    try {
      this.$store.dispatch(LOGOUT)
      this.goto('login')
    } catch (error) {
      errorHandler([error])
    }
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }
}
</script>
<style lang="scss">
.dropdown {
  &--left-sider-mobile {
    .ant-menu {
      position: fixed;
      z-index: 10;
      left: -100%;
      top: 0 !important;
      bottom: 0;
      width: 240px;
      transition: all 0.5s ease 0s;

      &.open {
        left: 0;
      }
    }

    &__overlay {
      z-index: 9;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
