import axios from 'axios'
import store from '@/store'
import { OPEN_DIALOG } from '@/store/actions'
import { GOOGLE_API_KEY } from '@/utils/constants'

const axiosInstance = axios.create({
  baseURL: 'https://www.googleapis.com/webfonts/v1/webfonts',
  timeout: 5000,
  withCredentials: false,
})

axiosInstance.interceptors.response.use((response) => {
  return response
}, (error) => {
  const errors = error.response?.data.errors
  switch (error.response?.status) {
    default:
      store.commit(OPEN_DIALOG, {
        title: 'Error',
        message: errors,
        confirmation: true,
        type: 'danger',
      })
      break
  }
})

const getGoogleFonts = async () => {
  const response = await axiosInstance.get('/', {
    params: {
      key: GOOGLE_API_KEY,
    },
  })

  return response?.data
}

export {
  getGoogleFonts,
}
