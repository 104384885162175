<template>
  <a-auto-complete class="w-full">
    <template #options>
      <a-select-option
        @click="onAdd(user.email)"
        v-for="user in usersListFilter"
        :key="user.email"
        :value="user.displayName"
      >
        {{ user.displayName }}
      </a-select-option>
    </template>
    <div>
      <div class="flex gap-2">
        <div class="flex-grow">
          <a-input placeholder="input email or username" v-model:value="selectedEmail">
            <template #suffix>
              <SearchOutlined />
            </template>
          </a-input>
        </div>
        <div>
          <a-button type="primary" :disabled="!isValidEmail(selectedEmail)" @click="onAdd(selectedEmail)">
            Add
          </a-button>
        </div>
      </div>
    </div>
  </a-auto-complete>

  <!-- Selected user list -->
  <div class="my-4">
    <UserTagByEmail @update:onRemove="onRemove" v-for="email in selectedEmailList" :key="email" :email="email" />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { isValidEmail } from '@/utils/validators'
import { SearchOutlined } from '@ant-design/icons-vue'
import UserTagByEmail from '@/components/user/UserTagByEmail.vue'
import { IUser } from '@/utils/types'
import { getUsers } from '@/services/users'

@Options({
  components: { UserTagByEmail, SearchOutlined },
  directives: { maska },
  emits: ['update:onUpdate'],
})
export default class SearchUserInput extends Vue {
  @Prop({ default: [] })
  emailList!: string[]

  selectedEmailList = [] as string[]

  selectedEmail = ''
  usersList: IUser[] = []

  get loading() {
    return this.$store.state.board.loading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get usersListFilter() {
    if (!this.selectedEmail) {
      return []
    }

    const userList = this.usersList.filter((item) => {
      const match1 = `${item.displayName}${item.email}`.toLowerCase().indexOf(this.selectedEmail.toLowerCase()) !== -1

      const match2 = item.email !== this.userInfo.email
      const match3 = !this.selectedEmailList.includes(item.email)

      return match1 && match2 && match3
    })

    return userList.splice(0, 4)
  }

  @Emit('update:onUpdate')
  onAdd(email: string) {
    this.selectedEmail = ''
    this.selectedEmailList.push(email)
    return this.selectedEmailList
  }

  @Emit('update:onUpdate')
  onRemove(email: string) {
    const index = this.selectedEmailList.indexOf(email)
    this.selectedEmailList.splice(index, 1)
    return this.selectedEmailList
  }

  isValidEmail(email: string) {
    return isValidEmail(email)
  }

  async created() {
    this.selectedEmailList = this.emailList
    this.usersList = await getUsers()
  }
}
</script>
