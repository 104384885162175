
import { Vue, Options } from 'vue-class-component'
import { Prop, Emit } from 'vue-property-decorator'
import { VuemojiPicker, EmojiClickEventDetail } from 'vuemoji-picker'
import { SmileOutlined } from '@ant-design/icons-vue'

@Options({
  components: { VuemojiPicker, SmileOutlined },
})
export default class BoardEmojiSelector extends Vue {
  @Prop()
  isShowEmojiList!: boolean

  @Prop()
  buttonClass?: string

  @Prop()
  listClass?: string

  @Prop()
  buttonSize?: string

  @Prop()
  isGhost?: string

  @Emit('update:emoji')
  handleEmojiClick(detail: EmojiClickEventDetail) {
    return detail?.unicode || ''
  }

  @Emit('update:showEmoji')
  onClickEmoji() {
    return !this.isShowEmojiList
  }

  @Emit('update:onClose')
  onClickOutside(event: Event) {
    if (!(/SVGSVGElement|HTMLButtonElement/i.test(event.target?.toString() || ''))) {
      return false
    }

    return this.isShowEmojiList
  }

  mounted() {
    document.addEventListener('click', this.onClickOutside)
  }

  beforeDestroy() {
    document.removeEventListener('click', this.onClickOutside)
  }
}
