import FireBoard from '@/services/boards'
import { IBoard, IUser } from '@/utils/types'
import {
  CLEAR_LOADING,
  SET_LOADING,
  SET_BOARDS,
  LOAD_BOARDS,
  LOAD_BOARD,
  ADD_BOARD,
  SET_BOARD,
  UPDATE_BOARD,
  DELETE_BOARD,
  DELETE_BOARD_SUCCESS,
  LOAD_BOARDS_RECEIVED,
  CLEAR_BOARD,
  LOAD_BOARDS_WAITING,
  LOAD_FONTS,
  SET_FONT_LIST,
} from './actions'

import { getGoogleFonts } from '@/services/google-fonts'
import { BOARD_FONTS_LIST } from '@/utils/constants'

type TBoardState = {
  items: IBoard[]
  item: IBoard | null
  loading: boolean
  fontsList: string[]
}

export default {
  state: {
    items: [],
    item: {},
    loading: false,
    fontsList: [],
  },
  getters: {
  },
  mutations: {
    [SET_LOADING]: (state: TBoardState) => {
      state.loading = true
    },
    [SET_BOARDS]: (state: TBoardState, { items }: { items: IBoard[] }) => {
      state.items = items || []
    },
    [SET_BOARD]: (state: TBoardState, { item }: { item: IBoard }) => {
      state.item = item
    },
    [SET_FONT_LIST]: (state: TBoardState, fontsList: string[]) => {
      state.fontsList = [...state.fontsList, ...fontsList]
    },
    [DELETE_BOARD_SUCCESS]: (state: TBoardState, { id }: { id: string }) => {
      const item = state.items.find(item => item.id === id)
      if (item) {
        state.items.splice(state.items.indexOf(item), 1)
      }
    },
    [CLEAR_LOADING]: (state: TBoardState) => {
      state.loading = false
    },
    [CLEAR_BOARD]: (state: TBoardState) => {
      state.items = []
      state.item = null
    },
  },
  actions: {
    [LOAD_BOARDS]: async ({ commit }: { commit: Function }, user: IUser) => {
      try {
        commit(SET_LOADING)
        const boards = await FireBoard.listGives(user)

        commit(SET_BOARDS, { items: boards })
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [LOAD_BOARDS_RECEIVED]: async ({ commit }: { commit: Function }, user: IUser) => {
      try {
        commit(SET_LOADING)
        // const boards = await getBoardsReceived(user)
        const boards = await FireBoard.listReceived(user)
        commit(SET_BOARDS, { items: boards })
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [LOAD_BOARDS_WAITING]: async ({ commit }: { commit: Function }, user: IUser) => {
      try {
        commit(SET_LOADING)
        const boards = await FireBoard.listWaiting(user)
        commit(SET_BOARDS, { items: boards })
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [LOAD_BOARD]: async ({ commit }: { commit: Function }, id: string) => {
      try {
        commit(SET_LOADING)
        const board = await FireBoard.get(id)
        commit(SET_BOARD, { item: board })
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [ADD_BOARD]: async ({ commit }: { commit: Function }, board: IBoard) => {
      try {
        commit(SET_LOADING)
        await FireBoard.add(board)
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [UPDATE_BOARD]: async ({ commit }: { commit: Function }, board: IBoard) => {
      try {
        commit(SET_LOADING)
        await FireBoard.update(board)
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [DELETE_BOARD]: async ({ commit }: { commit: Function }, id: string) => {
      try {
        commit(SET_LOADING)
        await FireBoard.delete(id)
        commit(DELETE_BOARD_SUCCESS, { id })
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [CLEAR_BOARD]: async ({ commit }: { commit: Function }) => {
      commit(CLEAR_BOARD)
    },
    [LOAD_FONTS]: async ({ commit }: { commit: Function }) => {
      try {
        commit(SET_LOADING)

        let fontsList = await getGoogleFonts()
        fontsList = fontsList && fontsList?.items?.length > 0 ? fontsList?.items : BOARD_FONTS_LIST

        commit(SET_FONT_LIST, fontsList)
      } finally {
        commit(CLEAR_LOADING)
      }
    },
  },
}
