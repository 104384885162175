<template>
  <a class="app-layout__logo block truncate" @click.prevent="gotoHome">
    <SmileOutlined class="text-xl" />
    <span class="app-layout__logo-text text-lg ml-2"> CorkBoard </span>
  </a>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { SmileOutlined } from '@ant-design/icons-vue'

@Options({
  components: { SmileOutlined },
})
export default class AppLogo extends Vue {
  gotoHome() {
    this.$router.push({ name: 'gives' })
  }
}
</script>
