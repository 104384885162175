
import { Vue, Options } from 'vue-class-component'
import { LOGOUT } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'

import { DownOutlined, LogoutOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue'
import { Watch } from 'vue-property-decorator'
import AppLogo from '@/components/common/AppLogo.vue'
import UserAvatar from '@/components/common/UserAvatar.vue'
import IconGive from '@/components/svg/IconGive.vue'
import IconReceive from '@/components/svg/IconReceive.vue'
import IconWait from '@/components/svg/IconWait.vue'
import { IUser } from '@/utils/types'
import { localStore } from '@/utils/localstore'

@Options({
  components: {
    AppLogo,
    UserAvatar,
    IconGive,
    IconReceive,
    IconWait,
    LogoutOutlined,
    DownOutlined,
    QuestionCircleOutlined,
  },
})
export default class AppSidebarLeft extends Vue {
  isCollapsed = false
  collapseKey = 'leftmenu-collapse'

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get activeRouteKey() {
    return this.$route.meta?.menuKey
  }

  @Watch('isCollapsed')
  onCollapseChanged() {
    if (this.isCollapsed) {
      localStore.setItem(this.collapseKey, `${this.isCollapsed}`)
    } else {
      localStore.removeItem(this.collapseKey)
    }
  }

  logout() {
    try {
      this.$store.dispatch(LOGOUT)
      this.goto('login')
    } catch (error) {
      errorHandler([error])
    }
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  created() {
    this.isCollapsed = !!localStore.getItem(this.collapseKey)
  }
}
