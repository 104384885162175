<template>
  <div></div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { IBoard, IRecipient, IUser } from '@/utils/types'
import { LOAD_BOARD, LOAD_BOARDS } from '@/store/actions'
import { BOARD_DELIVERY_TYPE, BOARD_PERMISSION_TYPE } from '@/utils/constants'

@Options({
  components: {},
})
export default class BoardFormMixin extends Vue {
  BOARD_PERMISSION_TYPE = BOARD_PERMISSION_TYPE
  BOARD_DELIVERY_TYPE = BOARD_DELIVERY_TYPE

  requiredRule = {
    required: true,
    message: 'This field is required',
    trigger: 'blur',
  }

  form: IBoard = {}

  rules = {}

  get loading() {
    return this.$store.state.board.loading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get board(): IBoard {
    return this.$store.state.board.item
  }

  get isNew() {
    return !!this.id
  }

  onAddNewPermissionEmail(emails: string[]) {
    this.form.contributorEmails = emails
  }

  validateRecipients(rule: { field: string }) {
    const index = Number(rule.field.split('.')[1])
    return new Promise((resolve, reject) => {
      /* eslint-disable */
      if (this.form.recipients?.length && !this.form.recipients[index]?.email) {
        return reject(new Error())
      }

      return resolve(true)
    })
  }

  addRecipient = () => {
    this.form.recipients?.push({
      name: '',
      email: '',
      key: Date.now(),
    })
  }

  removeRecipient = (item: IRecipient) => {
    const index = this.form.recipients?.indexOf(item)
    if (index === undefined) {
      return
    }

    if (index >= 0 && this.form.recipients) {
      this.form.recipients.splice(index, 1)
    }
  }

  beforeSave() {
    if (this.form.boardType !== BOARD_PERMISSION_TYPE.private) {
      this.form.contributorEmails = []
    }
  }

  async getBoard() {
    await this.$store.dispatch(LOAD_BOARD, this.id)
  }

  async getBoards() {
    await this.$store.dispatch(LOAD_BOARDS, this.userInfo)
  }
}
</script>
