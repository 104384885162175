
import { Vue, Options } from 'vue-class-component'
import { SmileOutlined } from '@ant-design/icons-vue'

@Options({
  components: { SmileOutlined },
})
export default class AppLogo extends Vue {
  gotoHome() {
    this.$router.push({ name: 'gives' })
  }
}
