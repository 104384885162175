
import { Vue, Options } from 'vue-class-component'

import {
  ExportOutlined,
  ImportOutlined,
  HourglassOutlined,
  LogoutOutlined,
  MenuOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons-vue'
import { Watch } from 'vue-property-decorator'
import AppLogo from '@/components/common/AppLogo.vue'
import UserAvatar from '@/components/common/UserAvatar.vue'
import { IUser } from '@/utils/types'
import { LOGOUT } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'

@Options({
  components: {
    AppLogo,
    UserAvatar,
    MenuOutlined,
    CloseCircleOutlined,
    LogoutOutlined,
    ExportOutlined,
    ImportOutlined,
    HourglassOutlined,
  },
})
export default class AppSidebarMobile extends Vue {
  menuOpen = false

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get activeRouteKey() {
    return `${this.$route.meta?.menuKey}_`
  }

  @Watch('$route')
  async onRouterChange() {
    this.menuOpen = false
  }

  logout() {
    try {
      this.$store.dispatch(LOGOUT)
      this.goto('login')
    } catch (error) {
      errorHandler([error])
    }
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }
}
