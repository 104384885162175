<template>
  <SvgBase iconName="VerticalThreeDots">
    <g version="1.1" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32" xml:space="preserve">
      <rect x="6" y="15" class="st0" :style="{ stroke: color }" width="20" height="13" />
      <rect x="5" y="10" class="st0" :style="{ stroke: color }" width="22" height="5" />
      <rect x="12" y="10" class="st0" :style="{ stroke: color }" width="8" height="5" />
      <rect x="13" y="15" class="st0" :style="{ stroke: color }" width="6" height="13" />
      <path
        class="st0"
        :style="{ stroke: color }"
        d="M23.7,5.4c-1-2-3.8-1.9-5,0.2L16,10l4.8,0C23.1,9.9,24.7,7.4,23.7,5.4z"
      />
      <path
        class="st0"
        :style="{ stroke: color }"
        d="M8.3,5.4c1-2,3.8-1.9,5,0.2L16,10l-4.8,0C8.9,9.9,7.3,7.4,8.3,5.4z"
      />
    </g>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconUser extends Vue {}
</script>
<style type="text/css" scoped>
.st0 {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
</style>
