
import { firebase } from '@/utils/firebase'

export class FireNorm {
  parse = <T>(doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>, fireChangeType?: string) => {
    return ({ id: doc.id, fireChangeType, ...doc.data() } as unknown) as T
  }

  nowTimeStamp = () => {
    return Math.round(new Date().getTime() / 1000)
  }
}
