import { firebase, userColl } from '@/utils/firebase'
import { uuidByString } from '@/utils/helpers'
import { IRegisterPayload, IUser } from '@/utils/types'

const firebaseUser = () => {
  const currentUser = firebase.auth().currentUser
  if (currentUser?.email) {
    return currentUser
  }

  return currentUser?.providerData[0]
}

const getUsers = async (): Promise<IUser[]> => {
  const users: IUser[] = []

  const docs = (await userColl.get()).docs

  docs.map(doc => {
    users.push({ id: doc.id, ...doc.data() as IUser })
  })

  return Promise.resolve(users)
}

const findUserByEmail = async (email: string): Promise<firebase.firestore.DocumentData> => {
  if (!email) {
    return Promise.reject(new Error('Unknow email'))
  }

  const userId = uuidByString(email)
  return await userColl.doc(userId).get()
}

const upsertUser = async (user: IUser) => {
  if (!user.email) {
    throw new Error('upsertUser: Unknow user email')
  }

  const existingRecord = await findUserByEmail(user.email)
  const userId = uuidByString(user.email)
  if (!existingRecord?.exists) {
    userColl.doc(userId).set({ ...user })
  } else {
    userColl.doc(userId).update(user)
  }
}

const doRegister = async (userRegister: IRegisterPayload) => {
  const register = await firebase.auth().createUserWithEmailAndPassword(userRegister.email, userRegister.password)
  if (register?.user) {
    return upsertUser({
      displayName: userRegister.displayName,
      email: userRegister.email,
      uid: register.user.uid,
      photoURL: register.user.photoURL || '',
    })
  }
}

const signInWithEmailAndPassword = (email: string, password: string) => {
  return firebase.auth().signInWithEmailAndPassword(email, password)
}

export {
  firebaseUser,
  getUsers,
  findUserByEmail,
  upsertUser,
  doRegister,
  signInWithEmailAndPassword,
}
