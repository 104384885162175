import { v5 as uuidv5 } from 'uuid'

const UUID5_NAME_SPACE = '00000000-0000-0000-0000-000000000000'

export const boardBackground = (background: string) => {
  if (background?.length > 7) {
    return { backgroundImage: `url(${background})` }
  }

  return { backgroundColor: background }
}

export const uuidByString = (str: string) => {
  return uuidv5(str, UUID5_NAME_SPACE)
}

export const appendCssFont = (family: string, variants?: string, subsets?: string) => {
  if (!family) return

  const checkFontIsExist = document.getElementById(family.replace(/\s/g, ''))
  if (checkFontIsExist) return

  const apiUrl = []
  apiUrl.push('https://fonts.googleapis.com/css?family=')
  apiUrl.push(family.replace(/\s/g, '+'))

  if (variants && /italic/i.test(variants)) {
    apiUrl.push(':')
    apiUrl.push('italic')
  }

  if (subsets && /greek/i.test(subsets)) {
    apiUrl.push('&subset=')
    apiUrl.push('greek')
  }

  const elLink = document.createElement('link')
  elLink.id = family.replace(/\s/g, '')
  elLink.rel = 'stylesheet'
  elLink.type = 'text/css'
  elLink.href = apiUrl.join('')

  const elHead = document.getElementsByTagName('head')[0]
  elHead.appendChild(elLink)
}
