export const GIPHY_API_KEY = 'CwOpi52uq8MeTIaiM2IWrTOB5gxULRNB'
export const UNSPLASH_API_KEY = 'lLRVP1bi5ch1j57pKW4gCkVEriMf5VNO_30qMHF2eqk'
export const GOOGLE_API_KEY = 'AIzaSyDEHbYVutTM_Z5DzCY2F1Ff5zs9L13RqBc'
export const BOARD_PERMISSION_TYPE = {
    public: 'public',
    private: 'private',
    password: 'password',
}

export const BOARD_DELIVERY_TYPE = {
    now: 'now',
    schedule: 'schedule',
}

export const BOARD_TABS = {
    gives: 'gives',
    received: 'received',
    waiting_for_you: 'waiting_for_you',
}

export const BOARD_BACKGROUND_LIST = [
    // patterns
    {
        group: 'pattern',
        titleBg: '#4f8583',
        boardBgThumb: '/img/board-bg/thumbs/noNIZ7pm.jpeg',
        boardBg: '/img/board-bg/AdxfAAr.jpeg',
    },
    {
        group: 'pattern',
        titleBg: '#851e4b',
        boardBgThumb: '/img/board-bg/thumbs/cNWZATM3.jpeg',
        boardBg: '/img/board-bg/z6US6OtL.jpeg',
    },
    {
        group: 'pattern',
        titleBg: '#2c4c69',
        boardBgThumb: '/img/board-bg/thumbs/YYw1GZDE.jpeg',
        boardBg: '/img/board-bg/HwLXcopn.jpeg',
    },

    // Solids
    {
        group: 'solid',
        titleBg: '#12738e',
        boardBg: '#9fbc96',
        boardBgThumb: '#9fbc96',
    },
    {
        group: 'solid',
        titleBg: '#1d3756',
        boardBg: '#d3b55c',
        boardBgThumb: '#d3b55c',
    },
    {
        group: 'solid',
        titleBg: '#a23a77',
        boardBg: '#f4c6c9',
        boardBgThumb: '#f4c6c9',
    },
]

export const BOARD_FONTS_LIST = [
    {
        family: 'Segoe UI',
    },
    {
        family: 'Courier New',
    },
    {
        family: 'Brush Script MT',
    },
]

export const TIMEZONE_LIST = {
    '-12:00': '(GMT-12:00) International Date Line West',
    '-11:00': '(GMT-11:00) Midway Island, Samoa',
    '-10:00': '(GMT-10:00) Hawaii',
    '-09:00': '(GMT-09:00) Alaska',
    '-08:00': '(GMT-08:00) Pacific Time (US & Canada)',
    '-07:00': '(GMT-07:00) Arizona',
    '-06:00': '(GMT-06:00) Central America, Central Time (US & Canada)',
    '-05:00': '(GMT-05:00) Bogota, Lima, Quito, Rio Branco, Eastern Time (US & Canada)',
    '-04:00': '(GMT-04:00) Atlantic Time (Canada)',
    '-03:00': '(GMT-03:00) Buenos Aires, Georgetown',
    '-02:00': '(GMT-02:00) Mid-Atlantic',
    '-01:00': '(GMT-01:00) Azores',
    '+00:00': '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
    '+01:00': '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    '+02:00': '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    '+03:00': '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
    '+03:30': '(GMT+03:30) Tehran',
    '+04:00': '(GMT+04:00) Yerevan',
    '+04:30': '(GMT+04:30) Kabul',
    '+05:00': '(GMT+05:00) Yekaterinburg, Islamabad, Karachi, Tashkent',
    '+05:30': '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    '+05:45': '(GMT+05:45) Kathmandu',
    '+06:00': '(GMT+06:00) Astana, Dhaka',
    '+06:30': '(GMT+06:30) Yangon (Rangoon)',
    '+07:00': '(GMT+07:00) Bangkok, Hanoi, Jakarta',
    '+08:00': '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    '+09:00': '(GMT+09:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
    '+09:30': '(GMT+09:30) Adelaide',
    '+10:00': '(GMT+10:00) Canberra, Melbourne, Sydney',
    '+11:00': '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
    '+12:00': '(GMT+12:00) Auckland, Wellington',
    '+13:00': "(GMT+13:00) Nuku'alofa",
}

export const TIME_SELECT_LIST = {
    '00:00': '00:00 AM',
    '01:00': '01:00 AM',
    '02:00': '02:00 AM',
    '03:00': '03:00 AM',
    '04:00': '04:00 AM',
    '05:00': '05:00 AM',
    '06:00': '06:00 AM',
    '07:00': '07:00 AM',
    '08:00': '08:00 AM',
    '09:00': '09:00 AM',
    '10:00': '10:00 AM',
    '11:00': '11:00 AM',
    '12:00': '12:00 PM',
    '13:00': '13:00 PM',
    '14:00': '14:00 PM',
    '15:00': '15:00 PM',
    '16:00': '16:00 PM',
    '17:00': '17:00 PM',
    '18:00': '18:00 PM',
    '19:00': '19:00 PM',
    '20:00': '20:00 PM',
    '21:00': '21:00 PM',
    '22:00': '22:00 PM',
    '23:00': '23:00 PM',
}
