<template>
  <a-layout-sider theme="dark" v-model:collapsed="isCollapsed" collapsible class="hidden md:block">
    <div class="p-4 text-center">
      <AppLogo />
    </div>

    <div class="flex-none px-2 py-4 ant-layout-sider__account-box">
      <a-dropdown>
        <div class="flex items-center gap-2">
          <UserAvatar />
          <div v-if="!isCollapsed" class="flex-grow truncate">
            <a @click.prevent @click="gotoUserProfile" class="font-bold text-white">
              {{ userInfo.displayName }}
            </a>
          </div>

          <span class="text-white">
            <DownOutlined />
          </span>
        </div>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="logout">
              <LogoutOutlined />
              Logout
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>

    <a-menu theme="dark" class="flex-grow border-0" mode="inline" :selectedKeys="[activeRouteKey]">
      <span class="block py-4 pl-6 text-gray-400">Menu</span>
      <a-menu-item key="gives" @click="goto('gives')" class="font-bold">
        <div class="flex items-center">
          <div class="anticon anticon--custom"><IconGive width="30" height="30" /></div>
          <span>You gave</span>
        </div>
      </a-menu-item>
      <a-menu-item key="received" @click="goto('received')" class="font-bold">
        <div class="flex items-center">
          <div class="-mt-1 anticon anticon--custom"><IconReceive width="30" height="30" /></div>
          <span>You received</span>
        </div>
      </a-menu-item>
      <a-menu-item key="waiting_for_you" @click="goto('waiting_for_you')" class="font-bold">
        <div class="flex items-center">
          <div class="anticon anticon--custom"><IconWait width="30" height="30" /></div>
          <span>Waiting for you</span>
        </div>
      </a-menu-item>
    </a-menu>

    <a-menu theme="dark" class="border-0" mode="inline" :selectedKeys="[activeRouteKey]" :key="activeRouteKey">
      <a-menu-item key="help" @click="goto('help')" class="font-bold">
        <QuestionCircleOutlined style="font-size: 1.1rem" />
        <span>Help</span>
      </a-menu-item>
    </a-menu>
  </a-layout-sider>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { LOGOUT } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'

import { DownOutlined, LogoutOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue'
import { Watch } from 'vue-property-decorator'
import AppLogo from '@/components/common/AppLogo.vue'
import UserAvatar from '@/components/common/UserAvatar.vue'
import IconGive from '@/components/svg/IconGive.vue'
import IconReceive from '@/components/svg/IconReceive.vue'
import IconWait from '@/components/svg/IconWait.vue'
import { IUser } from '@/utils/types'
import { localStore } from '@/utils/localstore'

@Options({
  components: {
    AppLogo,
    UserAvatar,
    IconGive,
    IconReceive,
    IconWait,
    LogoutOutlined,
    DownOutlined,
    QuestionCircleOutlined,
  },
})
export default class AppSidebarLeft extends Vue {
  isCollapsed = false
  collapseKey = 'leftmenu-collapse'

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get activeRouteKey() {
    return this.$route.meta?.menuKey
  }

  @Watch('isCollapsed')
  onCollapseChanged() {
    if (this.isCollapsed) {
      localStore.setItem(this.collapseKey, `${this.isCollapsed}`)
    } else {
      localStore.removeItem(this.collapseKey)
    }
  }

  logout() {
    try {
      this.$store.dispatch(LOGOUT)
      this.goto('login')
    } catch (error) {
      errorHandler([error])
    }
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  created() {
    this.isCollapsed = !!localStore.getItem(this.collapseKey)
  }
}
</script>
<style lang="scss">
.ant-layout-sider {
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: calc(100vh - 50px);
    left: 0;
    right: 0;
    top: 0;
    border: 0;
    width: 200px;
    transition: width 0.2s;
  }
  &.ant-layout-sider-collapsed {
    .ant-layout-sider-children {
      width: 80px;
    }
  }
  .anticon--custom {
    line-height: 0 !important;
  }
  &__account-box {
    background: #404040;
  }

  .app-layout__logo {
    color: #fff;
  }

  &.ant-layout-sider-collapsed {
    .anticon--custom {
      margin-left: -8px !important;
    }
    .app-layout__logo-text {
      display: none;
      transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
    }
  }
  .ant-layout-sider-trigger {
    border-top: 1px solid #404040;
  }
}
</style>
