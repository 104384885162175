import uniqBy from 'lodash/uniqBy'
import moment from 'moment-timezone'
import { boardColl } from '@/utils/firebase'
import { FireNorm } from './norm'
import { IBoard, IRecipient, IUser } from '@/utils/types'
import FireBoardItem from './board-items'

export class FireBoard extends FireNorm {
  private unsubscribeSnapshot: Function = () => false

  get BoardItems() {
    return FireBoardItem
  }

  get ref() {
    return boardColl
  }

  on = (callback: Function) => {
    this.unsubscribeSnapshot = this.ref.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          callback(this.parse<IBoard>(change.doc, change.type))
        }
      })
    })
  }

  off() {
    this.unsubscribeSnapshot()
  }

  listGives = async (user: IUser): Promise<IBoard[]> => {
    const boards: IBoard[] = []
    if (!user.id || !user.email) {
      return []
    }

    // Boards created by userId
    const docs1 = (await this.ref.where('userId', '==', user.id).get()).docs || []
    // Boards as a contributor
    const docs2 = (await this.ref.where('contributorEmails', 'array-contains', user.email).get()).docs || []
    // Board has card this user added
    const docs3 = (await this.ref.where('contributedUserIds', 'array-contains', user.id).get()).docs || []

    // Merge docs
    const docs = docs1.concat(docs2).concat(docs3)

    docs.map(doc => {
      boards.push(this.parse<IBoard>(doc))
    })

    const uniqueBoards = uniqBy(boards, 'id')
    return Promise.resolve(uniqueBoards.sort((a, b) => (b.createdAt as number) - (a.createdAt as number)))
  }

  listReceived = async (user: IUser): Promise<IBoard[]> => {
    const boards: IBoard[] = []
    if (!user.id || !user.email) {
      return []
    }

    const docs = (await this.ref.where('recipientEmails', 'array-contains', user.email).get()).docs || []

    docs.map(doc => {
      boards.push(this.parse<IBoard>(doc))
    })

    return Promise.resolve(uniqBy(boards, 'id'))
  }

  listWaiting = async (user: IUser): Promise<IBoard[]> => {
    const boards: IBoard[] = []
    if (!user.id || !user.email) {
      return []
    }

    const docs = (await this.ref.where('contributorEmails', 'array-contains', user.email).get()).docs || []

    docs.map(doc => {
      const docData = doc.data()
      const contributedUserIds: string[] = docData.contributedUserIds || []
      if (user.id && !contributedUserIds.includes(user.id)) {
        boards.push(this.parse<IBoard>(doc))
      }
    })

    return Promise.resolve(uniqBy(boards, 'id'))
  }

  getRecipientEmails = (board: IBoard) => {
    return board.recipients?.map((item: IRecipient) => item.email) || []
  }

  get = async (id: string) => {
    const doc = await this.ref.doc(id).get()
    return this.parse(doc)
  }

  add = async (board: IBoard) => {
    if (!board.title) {
      throw new Error('Unknow board title to save')
    }

    board.createdAt = moment().unix()
    board.updatedAt = moment().unix()
    this.ref.add({ ...board, recipientEmails: this.getRecipientEmails(board) })
  }

  update = async (board: IBoard) => {
    board.updatedAt = moment().unix()
    // eslint-disable-next-line
    const { id, fireChangeType, ...updateData } = board

    this.ref.doc(id).update({
      ...updateData,
      recipientEmails: this.getRecipientEmails(board),
    })
  }

  async updateContributedStatus(boardId?: string) {
    if (!boardId) {
      throw new Error('Unknow boardId')
    }

    const items = await FireBoardItem.board(boardId).list()
    const contributedUserIds = items.map(item => item.userId || '')
    await this.update({
      id: boardId,
      contributedUserIds: [...new Set(contributedUserIds)],
      contributedCount: items.length,
    })
  }

  delete = async (id: string) => {
    await this.ref.doc(id).delete()
    return this.BoardItems.board(id).deleteAll()
  }
}

export default new FireBoard()
