import { LOAD_PROFILE, SAVE_PROFILE, CLEAR_PROFILE, SET_LOADING, CLEAR_LOADING, LOGOUT, LOGIN, OPEN_DIALOG, LOGIN_FROM_FIREBASE, REGISTER } from './actions'
import { IUser, ILoginPayload, IRegisterPayload } from '@/utils/types'
import { doRegister, findUserByEmail, firebaseUser, signInWithEmailAndPassword, upsertUser } from '@/services/users'
import { firebase } from '@/utils/firebase'

type TProfileState = {
  user: IUser | null
  alreadyGetProfileOnInit: boolean
  loading: boolean
}

export default {
  state: {
    user: null,
    alreadyGetProfileOnInit: false,
    loading: false,
  },
  getters: {
    isAuth: (state: TProfileState) => {
      return !!state.user
    },
    isLoading: (state: TProfileState) => {
      return state.loading
    },
    userInfo: (state: TProfileState) => {
      return state.user
    },
    alreadyGetProfileOnInit: (state: TProfileState) => {
      return state.alreadyGetProfileOnInit
    },
  },
  mutations: {
    [SAVE_PROFILE]: (state: TProfileState, { user }: {
      user: IUser
    }) => {
      state.user = user
      state.alreadyGetProfileOnInit = true
      state.loading = false
    },
    [CLEAR_PROFILE]: (state: TProfileState) => {
      state.user = null
      state.loading = false
    },
    [SET_LOADING]: (state: TProfileState) => {
      state.loading = true
    },
    [CLEAR_LOADING]: (state: TProfileState) => {
      state.loading = false
    },
  },
  actions: {
    [LOAD_PROFILE]: async ({ commit, dispatch, state }: {
      commit: Function
      dispatch: Function
      state: TProfileState
    }) => {
      try {
        if (state.user) {
          return
        }

        const fuser = firebaseUser()
        if (fuser?.email) {
          commit(SET_LOADING)
          const userDoc = await findUserByEmail(fuser.email)

          let displayName = userDoc?.data()?.displayName

          if (!fuser?.displayName && !displayName) {
            displayName = fuser?.email.split('@')[0]
          }

          commit(SAVE_PROFILE, {
            user: {
              id: userDoc.id,
              ...userDoc.data(),
              displayName,
            },
          })
        }
      } catch (error) {
        if (error.message) {
          dispatch(OPEN_DIALOG, {
            message: error.message,
            needtranslation: true,
          })

          dispatch(LOGOUT)
        } else {
          commit(CLEAR_PROFILE)
        }
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [SAVE_PROFILE]: async ({ commit }: { commit: Function }, user: IUser | null) => {
      commit(SAVE_PROFILE, { user })
    },
    [LOGOUT]: async ({ commit }: { commit: Function }) => {
      commit(CLEAR_PROFILE)
      firebase.auth().signOut()
    },
    [REGISTER]: async ({ commit, dispatch }: { commit: Function, dispatch: Function }, payload: IRegisterPayload) => {
      try {
        commit(SET_LOADING)
        await doRegister(payload)
        dispatch(LOGIN, { email: payload.email, password: payload.password })
      } catch (error) {
        throw error
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [LOGIN]: async ({ commit, dispatch }: { commit: Function, dispatch: Function }, payload: ILoginPayload) => {
      try {
        commit(SET_LOADING)
        await signInWithEmailAndPassword(payload.email, payload.password)
        dispatch(LOAD_PROFILE)
      } catch (error) {
        throw error
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [LOGIN_FROM_FIREBASE]: async ({ commit, dispatch }: { commit: Function, dispatch: Function }, payload: IUser) => {
      try {
        commit(SET_LOADING)
        upsertUser(payload).then(() => {
          dispatch(LOAD_PROFILE)
        })
      } catch (error) {
        throw error
      } finally {
        commit(CLEAR_LOADING)
      }
    },
  },
}
