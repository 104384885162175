import BackGroundBoard from '@/services/background'
import { IBoardBackGround, IUser } from '@/utils/types'
import {
    LOAD_BACKGROUND,
    ADD_BACKGROUND,
    DELETE_BACKGROUND,
    UPDATE_BACKGROUND,
    SET_LOADING,
    CLEAR_LOADING,
    DELETE_BACKGROUND_SUCCESS,
    SET_BACKGROUNDS,
    SET_BACKGROUND,
} from './actions'

type TBoardBackGroundState = {
    items: IBoardBackGround[]
    item: IBoardBackGround | null
    loading: boolean
}

export default {
    state: {
        items: [],
        item: {},
        loading: false,
    },
    getters: {
    },
    mutations: {
        [SET_LOADING]: (state: TBoardBackGroundState) => {
            state.loading = true
        },
        [SET_BACKGROUNDS]: (state: TBoardBackGroundState, { items }: { items: IBoardBackGround[] }) => {
            state.items = items || []
        },
        [SET_BACKGROUND]: (state: TBoardBackGroundState, { item }: { item: IBoardBackGround }) => {
            state.item = item
        },
        [DELETE_BACKGROUND_SUCCESS]: (state: TBoardBackGroundState, { id }: { id: string }) => {
            const item = state.items.find(item => item.id === id)
            if (item) {
                state.items.splice(state.items.indexOf(item), 1)
            }
        },
        [CLEAR_LOADING]: (state: TBoardBackGroundState) => {
            state.loading = false
        },
    },
    actions: {
        [LOAD_BACKGROUND]: async ({ commit }: { commit: Function }, user: IUser) => {
            try {
                commit(SET_LOADING)
                const boards = await BackGroundBoard.background(user.id).list()

                commit(SET_BACKGROUNDS, { items: boards })
            } finally {
                commit(CLEAR_LOADING)
            }
        },
        [ADD_BACKGROUND]: async ({ commit }: { commit: Function }, payload: { background: IBoardBackGround, user: IUser }) => {
            try {
                commit(SET_LOADING)
                await BackGroundBoard.background(payload.user.id).add(payload.background)
            } finally {
                commit(CLEAR_LOADING)
            }
        },
        [UPDATE_BACKGROUND]: async ({ commit }: { commit: Function }, payload: { background: IBoardBackGround, user: IUser }) => {
            try {
                commit(SET_LOADING)
                await BackGroundBoard.background(payload.user.id).update(payload.background)
            } finally {
                commit(CLEAR_LOADING)
            }
        },
        [DELETE_BACKGROUND]: async ({ commit }: { commit: Function }, payload: { id: string, user: IUser }) => {
            try {
                commit(SET_LOADING)
                await BackGroundBoard.background(payload.user.id).delete(payload.id)
                commit(DELETE_BACKGROUND_SUCCESS, { id: payload.id })
            } finally {
                commit(CLEAR_LOADING)
            }
        },
    },
}
