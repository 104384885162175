<template>
  <a-modal
    class="max-w-full"
    width="1000px"
    :visible="modalVisible"
    @cancel="onCancel"
    title="Create new Sango CorkBoard"
  >
    <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
      <a-form-item label="What title would you like on top of the board?" name="title">
        <a-input v-model:value="form.title" autocomplete="title" placeholder="welcome onboarding" />
        <BoardEmojiSelector
          :isShowEmojiList="isShowEmoji"
          :buttonClass="'absolute top-0 right-0 mx-1 my-1 border-none'"
          :listClass="'absolute right-0 z-10'"
          :buttonSize="'small'"
          @update:showEmoji="isShowEmoji = $event"
          @update:onClose="isShowEmoji = $event"
          @update:emoji="form.title += $event"
        />
      </a-form-item>

      <hr />
      <h2 class="text-md">
        Recipients infomation
        <a-tooltip placement="topLeft" title="Who do you want to send nice messages to?">
          <QuestionCircleOutlined />
        </a-tooltip>
      </h2>
      <hr />

      <a-form-item
        v-for="(recipient, index) in form.recipients"
        :key="recipient.key"
        :label="index === 0 ? 'Recipients' : ''"
        :name="['recipients', index, 'name']"
        :rules="{
          required: true,
          validator: validateRecipients,
          message: 'Recipient name and email can not be null',
          trigger: 'blur',
        }"
      >
        <div class="flex gap-2">
          <a-input v-model:value="recipient.name" placeholder="recipient name" class="flex-grow" />
          <a-input v-model:value="recipient.email" placeholder="recipient email" class="flex-grow" />
          <a-button danger shape="circle" @click="removeRecipient(recipient)" :disabled="form.recipients.length <= 1">
            <template #icon>
              <DeleteOutlined class="dynamic-delete-button" :disabled="form.recipients.length === 1" />
            </template>
          </a-button>
        </div>
      </a-form-item>

      <a-form-item>
        <a-button type="dashed" @click="addRecipient">
          <PlusOutlined />
          Add Recipient
        </a-button>
      </a-form-item>

      <hr />
      <h2 class="text-md">
        Contributors infomation
        <a-tooltip placement="topLeft" title="Who will add card to this board">
          <QuestionCircleOutlined />
        </a-tooltip>
      </h2>
      <hr />

      <!-- Board permission type -->
      <div class="my-4">
        <a-dropdown class="mr-2" placement="topLeft">
          <a class="ant-dropdown-link">
            Board Type
            <QuestionCircleOutlined />
          </a>
          <template #overlay>
            <div class="bg-gray-200 p-2">
              <div><strong>Public: </strong> Anybody have link can add card to board</div>
              <div><strong>Private: </strong> Only invited users or emails can add card to board</div>
              <div><strong>Password secret: </strong> Anybody have link and password can add card to board</div>
            </div>
          </template>
        </a-dropdown>

        <a-radio-group v-model:value="form.boardType" button-style="solid">
          <a-radio-button :value="BOARD_PERMISSION_TYPE.public">Public</a-radio-button>
          <a-radio-button :value="BOARD_PERMISSION_TYPE.private">Private</a-radio-button>
          <a-radio-button :value="BOARD_PERMISSION_TYPE.password">Password secret</a-radio-button>
        </a-radio-group>
      </div>

      <a-alert
        v-if="form.boardType === BOARD_PERMISSION_TYPE.public"
        message="Anyone have link can add card to board"
        type="warning"
        show-icon
      />
      <SearchUserInput
        @update:onUpdate="onAddNewPermissionEmail"
        :emailList="form.contributorEmails"
        v-if="form.boardType === BOARD_PERMISSION_TYPE.private"
      />

      <a-form-item
        label="Board password secret"
        name="boardPassword"
        v-if="form.boardType === BOARD_PERMISSION_TYPE.password"
      >
        <a-input v-model:value="form.boardPassword">
          <template #prefix><LockOutlined /></template>
        </a-input>
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button key="back" @click="onCancel">Cancel</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="onSubmitBoard"> Save </a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ADD_BOARD, UPDATE_BOARD } from '@/store/actions'
import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined, LockOutlined } from '@ant-design/icons-vue'
import SearchUserInput from '@/components/user/SearchUserInput.vue'
import BoardFormMixin from '@/components/board/mixins/BoardFormMixin.vue'
import { IBoard } from '@/utils/types'
import { BOARD_BACKGROUND_LIST, BOARD_FONTS_LIST } from '@/utils/constants'
import BoardEmojiSelector from './BoardEmojiSelector.vue'

@Options({
  components: {
    SearchUserInput,
    BoardEmojiSelector,
    QuestionCircleOutlined,
    DeleteOutlined,
    LockOutlined,
    PlusOutlined,
  },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardFormModal extends BoardFormMixin {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  form: IBoard = {
    title: '',

    recipients: [
      {
        key: Date.now(),
        name: '',
        email: '',
      },
    ],
    userId: '',
    userName: '',
    boardBg: BOARD_BACKGROUND_LIST[0].boardBg,
    titleBg: BOARD_BACKGROUND_LIST[0].titleBg,
    fontFamily: BOARD_FONTS_LIST[0].family,
    boardType: 'public',
    boardPassword: '',
    contributorEmails: [] as string[],
  }

  rules = {
    title: [this.requiredRule],
    boardPassword: [this.requiredRule],
  }

  isShowEmoji = false

  onSubmitBoard() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doSaveBoard()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSaveBoard() {
    this.beforeSave()

    if (!this.id && this.userInfo.id) {
      this.form.userId = this.userInfo.id
      this.form.userName = this.userInfo.displayName
      await this.$store.dispatch(ADD_BOARD, this.form)

      // Back to list gives
      this.$router.push({
        name: 'gives',
      })
    } else {
      await this.$store.dispatch(UPDATE_BOARD, this.form)
    }

    // Reload board list
    this.getBoards()
    this.onCancel()
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async created() {
    if (this.id) {
      await this.getBoard()
      this.form = { ...this.form, ...JSON.parse(JSON.stringify(this.board)) }
    }
  }
}
</script>
