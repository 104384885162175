// App
export const OPEN_DIALOG = 'app/openDialog'
export const CLOSE_DIALOG = 'app/closeDialog'
export const SET_PAGE_NOT_FOUND = 'app/setPage404'
export const RESET_PAGE_NOT_FOUND = 'app/resetPage404'
export const SET_LOADING = 'app/setLoading'
export const CLEAR_LOADING = 'app/clearLoading'

// Profile
export const SAVE_PROFILE = 'profile/saveProfile'
export const LOAD_PROFILE = 'profile/fetchProfile'
export const LOGOUT = 'profile/logout'
export const LOGIN = 'profile/login'
export const REGISTER = 'profile/register'
export const LOGIN_FROM_FIREBASE = 'profile/loginFromSocial'
export const CLEAR_PROFILE = 'profile/clearProfile'

// Users
export const LOAD_BOARDS = 'board/fetchBoards'
export const LOAD_BOARDS_RECEIVED = 'board/fetchBoardsReceived'
export const LOAD_BOARDS_WAITING = 'board/fetchBoardsWaiting'
export const SET_BOARDS = 'board/setBoards'
export const SET_BOARD = 'board/setBoard'
export const LOAD_BOARD = 'board/fetchBoard'
export const CLEAR_BOARD = 'board/clearBoard'
export const ADD_BOARD = 'board/add'
export const UPDATE_BOARD = 'board/update'
export const DELETE_BOARD = 'board/delete'
export const DELETE_BOARD_SUCCESS = 'board/deleteSuccess'
export const LOAD_FONTS = 'board/fetchGooleFonts'
export const SET_FONT_LIST = 'board/setFontsList'

// Background
export const LOAD_BACKGROUND = 'background/fetchBackgrounds'
export const SET_BACKGROUNDS = 'board/setBackgrounds'
export const SET_BACKGROUND = 'board/setBackground'
export const ADD_BACKGROUND = 'background/add'
export const UPDATE_BACKGROUND = 'background/update'
export const DELETE_BACKGROUND = 'background/delete'
export const DELETE_BACKGROUND_SUCCESS = 'background/deleteSuccess'
