import moment from 'moment-timezone'
import { FireNorm } from './norm'
import { boardItemColl } from '@/utils/firebase'
import { IBoardItem } from '@/utils/types'
import FireBoard from './boards'

export class FireBoardItem extends FireNorm {
  private boardId: string | undefined = ''
  private unsubscribeSnapshot: Function = () => true

  get ref() {
    if (!this.boardId) {
      throw new Error('Unknow boardId')
    }

    return boardItemColl.doc(this.boardId).collection('private')
  }

  board(boardId?: string) {
    this.boardId = boardId
    return this
  }

  on = (callback: Function) => {
    this.unsubscribeSnapshot = this.ref.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        callback(this.parse<IBoardItem>(change.doc, change.type))
      })
    })
  }

  off() {
    this.unsubscribeSnapshot()
  }

  list = async (): Promise<IBoardItem[]> => {
    const messages: IBoardItem[] = []

    const docs = (await this.ref.limit(100).get()).docs

    docs.map(doc => {
      messages.push(this.parse<IBoardItem>(doc))
    })

    return messages
  }

  get = async (id: string) => {
    const doc = await this.ref.doc(id).get()
    return this.parse(doc)
  }

  add = async (message: IBoardItem) => {
    if (!message.message) {
      throw new Error('Unknow message to save')
    }

    message.createdAt = moment().unix()
    message.updatedAt = moment().unix()
    this.ref.add({ ...message })

    FireBoard.updateContributedStatus(this.boardId)
  }

  update = async (message: IBoardItem) => {
    message.updatedAt = moment().unix()
    // eslint-disable-next-line
    const { id, fireChangeType, ...updateData } = message

    await this.ref.doc(id).update({
      ...updateData,
    })

    return FireBoard.updateContributedStatus(this.boardId)
  }

  delete = async (id: string) => {
    await this.ref.doc(id).delete()
    return FireBoard.updateContributedStatus(this.boardId)
  }

  deleteAll = async () => {
    return boardItemColl.doc(this.boardId).delete()
  }
}

export default new FireBoardItem()
