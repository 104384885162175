<template>
  <SvgBase iconName="VerticalThreeDots">
    <svg
      version="1.1"
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      style="enable-background: new 0 0 32 32"
      xml:space="preserve"
    >
      <path class="st0" :style="{ stroke: color }" d="M3,24l2.6-4.2c1.5-2.3,4-3.8,6.8-3.8H19v0c0,2.2-1.8,4-4,4h-2" />
      <path
        class="st0"
        :style="{ stroke: color }"
        d="M15,20h8l1.2-1.6c1.1-1.5,2.9-2.4,4.8-2.4h0l-2.7,4.8c-1.4,2.6-4.2,4.2-7.1,4.2h0c-4.7,0-9.3,1.4-13.2,4l0,0"
      />
      <rect x="9" y="7" class="st0" :style="{ stroke: color }" width="16" height="4" />
      <path
        class="st0"
        :style="{ stroke: color }"
        d="M17,7h-1.7C14,7,13,6,13,4.7v0C13,3.8,13.8,3,14.7,3h0C16,3,17,4,17,5.3V7z"
      />
      <path
        class="st0"
        :style="{ stroke: color }"
        d="M17,7h1.7C20,7,21,6,21,4.7v0C21,3.8,20.2,3,19.3,3h0C18,3,17,4,17,5.3V7z"
      />
      <polyline class="st0" :style="{ stroke: color }" points="10.8,16 10.8,11 23.2,11 23.2,20 " />
      <rect x="-504" y="-432" class="st3" width="536" height="680" />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconUser extends Vue {}
</script>
<style type="text/css" scoped>
.st0 {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st1 {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 3;
}
.st2 {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st3 {
  fill: none;
}
</style>
