import { createStore } from 'vuex'

import ProfileModule from './profile'
import Board from './boards'
import Background from './background'
import { CLOSE_DIALOG, OPEN_DIALOG, SET_PAGE_NOT_FOUND, RESET_PAGE_NOT_FOUND } from './actions'

interface TAppState {
  dialogVisible: boolean
  dialogConfig: {
    title: string
    message: string
    confirmation: boolean
    needtranslation?: boolean
    type: string
  }
  isNotFound: boolean
  notFoundItem: string
}

const getDefaultDialogConfig = () => {
  return {
    title: '',
    message: '',
    confirmation: true,
    needtranslation: false,
    type: 'info',
  }
}

export default createStore({
  strict: true,
  state: {
    dialogVisible: false,
    dialogConfig: {
      title: '',
      message: '',
      confirmation: true,
      type: 'info',
    },
    isNotFound: false,
    notFoundItem: '',
  },
  mutations: {
    [OPEN_DIALOG]: (state: TAppState, payload: {
      title: string
      message: string
      confirmation: boolean
      type: string
    }) => {
      state.dialogVisible = true
      state.dialogConfig = payload
    },
    [CLOSE_DIALOG]: (state: TAppState) => {
      state.dialogVisible = false
      state.dialogConfig = getDefaultDialogConfig()
    },
    [SET_PAGE_NOT_FOUND]: (state: TAppState, payload?: { item: string }) => {
      state.isNotFound = true
      state.notFoundItem = payload?.item || ''
    },
    [RESET_PAGE_NOT_FOUND]: (state: TAppState) => {
      state.isNotFound = false
      state.notFoundItem = ''
    },
  },
  actions: {
    [OPEN_DIALOG]: ({ commit }: {
      commit: Function
    }, payload: {
      title?: string
      message: string
      confirmation?: boolean
      needtranslation?: boolean
      type?: string
    }) => {
      commit(OPEN_DIALOG, { ...getDefaultDialogConfig(), ...payload })
    },
    [CLOSE_DIALOG]: ({ commit }: {
      commit: Function
    }) => {
      commit(CLOSE_DIALOG)
    },
    [SET_PAGE_NOT_FOUND]: ({ commit }: {
      commit: Function
    }, payload: {
      item: string
    }) => {
      commit(SET_PAGE_NOT_FOUND, payload)
    },
    [RESET_PAGE_NOT_FOUND]: ({ commit }: {
      commit: Function
    }) => {
      commit(RESET_PAGE_NOT_FOUND)
    },
  },
  modules: {
    profile: ProfileModule,
    board: Board,
    background: Background,
  },
})
