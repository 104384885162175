
import { Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ADD_BOARD, UPDATE_BOARD } from '@/store/actions'
import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined, LockOutlined } from '@ant-design/icons-vue'
import SearchUserInput from '@/components/user/SearchUserInput.vue'
import BoardFormMixin from '@/components/board/mixins/BoardFormMixin.vue'
import { IBoard } from '@/utils/types'
import { BOARD_BACKGROUND_LIST, BOARD_FONTS_LIST } from '@/utils/constants'
import BoardEmojiSelector from './BoardEmojiSelector.vue'

@Options({
  components: {
    SearchUserInput,
    BoardEmojiSelector,
    QuestionCircleOutlined,
    DeleteOutlined,
    LockOutlined,
    PlusOutlined,
  },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardFormModal extends BoardFormMixin {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  form: IBoard = {
    title: '',

    recipients: [
      {
        key: Date.now(),
        name: '',
        email: '',
      },
    ],
    userId: '',
    userName: '',
    boardBg: BOARD_BACKGROUND_LIST[0].boardBg,
    titleBg: BOARD_BACKGROUND_LIST[0].titleBg,
    fontFamily: BOARD_FONTS_LIST[0].family,
    boardType: 'public',
    boardPassword: '',
    contributorEmails: [] as string[],
  }

  rules = {
    title: [this.requiredRule],
    boardPassword: [this.requiredRule],
  }

  isShowEmoji = false

  onSubmitBoard() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doSaveBoard()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSaveBoard() {
    this.beforeSave()

    if (!this.id && this.userInfo.id) {
      this.form.userId = this.userInfo.id
      this.form.userName = this.userInfo.displayName
      await this.$store.dispatch(ADD_BOARD, this.form)

      // Back to list gives
      this.$router.push({
        name: 'gives',
      })
    } else {
      await this.$store.dispatch(UPDATE_BOARD, this.form)
    }

    // Reload board list
    this.getBoards()
    this.onCancel()
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async created() {
    if (this.id) {
      await this.getBoard()
      this.form = { ...this.form, ...JSON.parse(JSON.stringify(this.board)) }
    }
  }
}
